import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";

// pages
import Home from "views/Home";
import OmOss from "views/OmOss";
import KontaktaOss from "views/KontaktaOss";
import Vacuumsug from "views/Vacuumsug";
import Materialblasning from "views/Materialblasning";
import Gravsugning from "views/Gravsugning";
import Torrsugning from "views/Torrsugning";

// others
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        render={(props) => <Home {...props} />}
      />
      <Route
        path="/om-sug-och-blas-bil"
        render={(props) => <OmOss {...props} />}
      />
      <Route
        path="/vad-vi-arbetar-med"
        render={(props) => <OmOss {...props} />}
      />
      <Route 
        path="/kontakta-oss" 
        render={(props) => <KontaktaOss {...props} />} 
      />
      <Route 
        path="/lediga-tjanster" 
        render={(props) => <KontaktaOss {...props} />} 
      />
      <Route 
        path="/vacuumsug" 
        render={(props) => <Vacuumsug {...props} />} 
      />
      <Route 
        path="/materialblasning" 
        render={(props) => <Materialblasning {...props} />} 
      />
      <Route 
        path="/gravsugning" 
        render={(props) => <Gravsugning {...props} />} 
      />
      <Route 
        path="/torrsugning" 
        render={(props) => <Torrsugning {...props} />} 
      />
      <Redirect to="/home" />
    </Switch>
  </BrowserRouter>
);
