import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import MainFooter from "components/Footers/MainFooter";
import Hero from "components/Hero";
import MainSection from "components/MainSection";
import LinkSection from "components/LinkSection";
import Background from "components/Background";
import Button from "components/Button";

import omOssHero from "../assets/img/om_oss_hero.png";
import img_1 from "../assets/img/mat.png";
import img_2 from "../assets/img/grav.png";
import img_3 from "../assets/img/torr.png";
import img_4 from "../assets/img/vaccum.png";
import bg_logo from '../assets/img/bg-logo-final.png';

function OmOss() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const title = (
    <>
      Enkelt. Smart. Snabbt.
      <br />
      SUG & BLÅS BIL SVERIGE AB
    </>
  );

  const subDesc = [
    "Våra sugbilar är utrustade med högpresterande sugaggregat som kan hantera både våta och torra material, inklusive sand, grus, slam, olja och annat avfall. Vi kan hjälpa till med allt från avloppsspolning och industriella rengöringsarbeten till slamsugning och avfallshantering.",
    "Med våra specialutrustade lastbilar erbjuder vi en bred och bra service till anläggningsföretag, rivningsföretag, byggföretag, underhållsföretag, industrier, försäkringsbolag, fastighetsförvaltning och saneringsföretag.",
    "Vi utför arbete på vind, tak, källare och platser med trånga utrymmen m.m.Materialet förflyttar vi via slang till lastbil och från lastbil till anvisad plats såsom: hustak, en innergård, husdränering, en krypgrund eller platser där t. ex. grävmaskiner av utrymmesskäl inte kommer åt.",
    "Vi sätter alltid våra kunders behov i fokus och arbetar nära samarbete med er för att säkerställa att vi levererar den bästa lösningen för era specifika behov. Våra erfarna tekniker och operatörer är experter på att hantera sugbilar och säkerställer att arbetet utförs på ett säkert och effektivt sätt."
  ];

  return (
    <>
      <Head 
        pageTitle="Om Sug och Blåsbil Sverige AB - Sugbil - vacuumsugning och materialblåsning"
        pageDesc="Med våra specialutrustade lastbilar erbjuder vi en bred och bra service till anläggningsföretag, rivningsföretag, byggföretag, underhållsföretag..."
        link="om-sug-och-blas-bil/"
      />
      <MainNavigation />
      <Hero imageUrl={omOssHero} imageHeight="60vh" />
      <Background imgSrc={bg_logo}/>
      <MainSection
        showSecondRow={false}
        showThirdRow={false}
        showTitle={true}
        title={title}
        showSub={true}
        subDescription={subDesc}
      />
      <Button url="/kontakta-oss" name="Kontakta oss" />
      <LinkSection 
        showSecondRow={true}
        linkOne="/materialblasning"
        linkTwo="/gravsugning"
        linkThree="/torrsugning"
        linkFour="/vacuumsug"
        imageOne={img_1}
        imageTwo={img_2}
        imageThree={img_3}
        imageFour={img_4}
        titleOne="Materialblåsning"
        titleTwo="Grävsugning"
        titleThree="Torrsugning"
        titleFour="Vacuumsug"
      />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default OmOss;
