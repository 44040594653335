import React from "react";

import { Container, Row, Col } from "reactstrap";

import envelope from "../assets/img/envelope.png";
import phoneIcon from "../assets/img/phone.png";

import Button from "./Button";

function MainSection({
  showSecondRow,
  showImageText,
  showThirdRow,
  showTitle,
  title,
  showSubTitle,
  subTitle,
  description,
  showDesc,
  showSub,
  subDescription,
  imageSrc,
  imageText,
  showSeparator,
}) {
  return (
    <div className="main-section">
      <div className="about-description text-center">
        <div className="features-3 main-section-features">
          <Container className="main-section-container">
            <Row className="main-section-row">
              <Col className="mr-auto ml-auto main-section-column">
                {showTitle && <h1 className="main-section-title">{title}</h1>}
                {showSubTitle && <h1 className="sub-page-title">{subTitle}</h1>}
                <div className="main-section-separator"></div>
                {showImageText && (
                  <Row className="main-section-image-text">
                    <Col md="4" className="main-section-column">
                      <img
                        className="main-section-image"
                        src={imageSrc}
                        alt=""
                      />
                    </Col>
                    <Col md="8" className="main-section-column">
                      {imageText.map((text) => (
                        <p className="sub-page-description">{text}</p>
                      ))}
                    </Col>
                  </Row>
                )}
                {showDesc && (
                  <h4 className="main-section-description">{description}</h4>
                )}
                {showSub &&
                  subDescription.map((subDesc) => (
                    <p className="sub-page-description">{subDesc}</p>
                  ))}

                {showSeparator && (
                  <div className="main-section-separator"></div>
                )}
              </Col>
            </Row>
            {showSecondRow && (
              <Row className="main-section-row">
                <Col md="6" xs="12" className="main-column-1">
                  <a className="main-column-box" href="/vacuumsug">
                    <div className="info info-hover">
                      <h4 className="info-title">Vacuumsug</h4>
                      <p className="main-column-description">
                        Vacuumsugning med en sugbil är en process som används
                        för att suga upp och transportera olika typer av
                        material och avfall.
                      </p>
                      <Button url="/vacuumsug" name="Läs mer" />
                    </div>
                  </a>
                </Col>
                <Col md="6" xs="12" className="main-column-2">
                  <a className="main-column-box" href="/materialblasning">
                    <div className="info info-hover">
                      <h4 className="info-title">Materialblåsning</h4>
                      <p className="main-column-description">
                        Med blåsfunktion är det ett effektivt sätt att få upp
                        material på tak, källare eller trånga utrymmen som t.
                        ex. krypgrund.
                      </p>
                      <Button url="/materialblasning" name="Läs mer" />
                    </div>
                  </a>
                </Col>
                <Col md="6" xs="12" className="main-column-3">
                  <a className="main-column-box" href="/torrsugning">
                    <div className="info info-hover">
                      <h4 className="info-title">Torrsugning</h4>
                      <p className="main-column-description">
                        Där en grävmaskin inte kommer åt kan vi frilägga
                        elkablar, rör mm. Vi kan även grävsuga i trånga
                        utrymmen, t.ex. krypgrund.
                      </p>
                      <Button url="/torrsugning" name="Läs mer" />
                    </div>
                  </a>
                </Col>
                <Col md="6" xs="12" className="main-column-4">
                  <a className="main-column-box" href="/gravsugning">
                    <div className="info info-hover">
                      <h4 className="info-title">Grävsugning</h4>
                      <p className="main-column-description">
                        Torrsugning med en sugbil är en effektiv metod för att
                        ta bort torrt material från ytor och ytor med begränsad
                        tillgänglighet
                      </p>
                      <Button url="/gravsugning" name="Läs mer" />
                    </div>
                  </a>
                </Col>
              </Row>
            )}
            {showThirdRow && (
              <Row className="row-contact">
                <Col md="4" className="ml-auto info-contact">
                  <div className="info-box">
                    <div className="icon">
                      <img
                        className="info-contact-icon"
                        src={envelope}
                        alt=""
                      />
                    </div>
                    <h4 className="info-contact-title">
                      <a href="mailto:info@sugbil.nu">info@sugbil.nu</a>
                    </h4>
                  </div>
                </Col>
                <Col md="4" className="mr-auto info-contact">
                  <div className="info-box info-box-2">
                    <div className="icon">
                      <img
                        className="info-contact-icon"
                        src={phoneIcon}
                        alt=""
                      />
                    </div>
                    <h4 className="info-contact-title">
                      <a href="tel:46766399868">076-6399868</a>
                      <br />
                      <br />
                      <a href="tel:+4608398810">08-398810</a>
                    </h4>
                  </div>
                </Col>
                <Col className="mr-auto ml-auto main-section-column address-column">
                  <h4 className="main-section-address">
                    Adress: Box 5205151 13<br></br> Södertälje<br></br>
                    <br></br>Besöksadress: Regementsgatan hus 353<br></br> 151
                    66 Södertälje
                  </h4>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
