import React from "react";

import { Container } from "reactstrap";

function Button({ name, url, borderRadius }) {
  const borderStyle = {
    borderRadius: `${borderRadius}px`,
  };

  return (
    <Container className="sugbil-btn-container">
      <a href={url} rel="noopener noreferrer">
        <button className="sugbil-btn" style={borderStyle}>
          {name}
        </button>
      </a>
    </Container>
  );
}

export default Button;
