import React from "react";

import { Container, Row, Col } from "reactstrap";

function ImageSection({ showTitle }) {
  return (
    <div className="image-section">
      <Container className="image-section-container">
        {showTitle && (
          <Row>
            <Col className="mr-auto ml-auto image-section-column">
              <h4 className="image-section-title">
                Tack för att du väljer att kontakta oss!
              </h4>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="4" xs="12">
            <img
              alt="..."
              className="rounded img-section-image"
              src={require("assets/img/IMG_1.png")}
            />
          </Col>
          <Col md="4" xs="12">
            <img
              alt="..."
              className="rounded img-section-image"
              src={require("assets/img/IMG_3.png")}
            />
          </Col>
          <Col md="4" xs="12">
            <img
              alt="..."
              className="rounded img-section-image"
              src={require("assets/img/IMG_2.png")}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ImageSection;
