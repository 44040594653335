import React from "react";

import { Container, Row, Col } from "reactstrap";

function Hero(props) {
  const titles = {
    title1: "Vacuumsug•",
    title2: "Materialblåsning•",
    title3: "Torrsugning•",
    title4: "Grävsugning",
  };

  const dynamicTitles = Object.values(titles).map((title, index) => (
    <h1 key={index} className="hero-title">
      {title}
    </h1>
  ));

  return (
    <Container
      className="hero-img"
      style={{
        backgroundImage: `url(${props.imageUrl})`,
        height: props.imageHeight,
      }}
    >
      <Row className="hero-row">
        <Col className="hero-column">
          {props.showTitle && dynamicTitles}
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
