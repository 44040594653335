import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import Hero from "components/Hero";
import MainSection from "components/MainSection";
import ImageSection from "components/ImageSection";
import Background from "components/Background";
import MainFooter from "components/Footers/MainFooter";

import homeHero from "../assets/img/hero-img.png";
import bg_logo from '../assets/img/bg-logo-final.png'

function Home() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const title = "Enkelt. Smart. Snabbt.";
  const desc =
    "Välkommen till vårt företag som specialiserar sig på sugbilslösningar för olika behov. Med vårt professionella team och moderna utrustning kan vi erbjuda effektiva och pålitliga lösningar för alla typer av sugbehov.";

  return (
    <>
      <Head 
        pageTitle="Sugbil- Vacuumsugning- Materialblåsning"
        pageDesc="Vacuumsug Vått som torrt på vindar, tak, källare, krypgrund, innegård m. m: Glasberg, Grus, Borrkax, Vatten, Aska, Löv, Sand, Singel, Sågspån, Lösull, Kutterspån, Byggmästarfyllning/Trossbotten, Fasadputs, Blästersand, Taksingel, Makadam, Lekarsten, Pellets, Snö, Stenmjöl Kol, Bilad betong, Isoleringsull&#8230; Materialblåsning Med blåsfunktion är det ett effektivt sätt att få upp material på tak, källare eller trånga utrymmen som Fortsätt läsa 'Hem';"
        link=""
      />
      <MainNavigation />
      <Hero 
        imageUrl={homeHero} 
        showTitle={true} 
        imageHeight="85vh" 
      />
      <Background imgSrc={bg_logo}/>
      <MainSection
        showTitle={true}
        title={title}
        showDesc={true}
        description={desc}
        showSecondRow={true}
        showThirdRow={false}
        showSeparator={false}
      />
      <ImageSection showTitle={false} />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default Home;
